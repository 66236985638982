import { useResponsibilities } from './useResponsibilities'

export const useFilteredResponsibilities = (responsibilities: string[]) => {
  const defaultResponsibilities = useResponsibilities()
  const filteredResponsibilities = defaultResponsibilities.filter(({ id }) =>
    responsibilities.includes(id)
  )

  return filteredResponsibilities
}

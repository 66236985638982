import React from 'react'
import { Flex } from '~/ui/core/Flex/Flex'
import { Tag } from '~/ui/core/Tag/Tag'
import { useFilteredResponsibilities } from './useFilteredResponsibilities'
import { useResponsibilities } from './useResponsibilities'

type ResponsibilitiesProps = {
  responsibilities?: string[]
}

export const Responsibilities = (props: ResponsibilitiesProps) => {
  const defaultResponsibilities = useResponsibilities()
  const filteredResponsibilities = useFilteredResponsibilities(props.responsibilities || [])
  const responsibilities = props.responsibilities
    ? filteredResponsibilities
    : defaultResponsibilities

  return (
    <Flex
      justifyContent="center"
      gap="md"
      wrap="wrap"
    >
      {responsibilities.map(({ accent, id, label, shortLabel }) => (
        <Tag
          accent={accent}
          key={id}
        >
          {shortLabel || label}
        </Tag>
      ))}
    </Flex>
  )
}

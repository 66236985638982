import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import { Content } from '~/common/Content/Content.styled'
import { ContentLayout } from '~/common/ContentLayout/ContentLayout'
import { Responsibilities } from '~/common/Responsibilities/Responsibilities'
import { WorkPagePath } from '~/pages/work'
import { WorkData } from '~/types/work'
import { Button } from '~/ui/core/Button/Button'
import { Flex } from '~/ui/core/Flex/Flex'
import { Text } from '~/ui/core/Text/Text'
import * as Styled from './Work.styled'

type WorkProps = {
  workData: WorkData
}

export const Work = ({
  workData: {
    data: { markdownRemark: work },
  },
}: WorkProps) => {
  const { frontmatter, html } = work
  const { description, image, title, responsibilities, role, year } = frontmatter
  const featuredImage = image ? getImage(image) : undefined

  return (
    <ContentLayout>
      <article>
        <Styled.Header>
          <Flex
            alignItems="center"
            direction="column"
            gap="xl"
          >
            <Text
              as="h1"
              align="center"
              color="pink"
              size="2xl"
              weight="bold"
            >
              {title}
            </Text>
            <Responsibilities responsibilities={responsibilities} />
            <Text
              align="center"
              as="p"
              size="base"
            >
              {description}
            </Text>
          </Flex>
        </Styled.Header>
        {featuredImage && (
          <Styled.Image
            image={featuredImage}
            alt={`${title}'s featured image`}
          />
        )}
        <Styled.DescriptionList>
          <Styled.MyRoleHeading>My Role</Styled.MyRoleHeading>
          <Styled.MyRoleDetails>{role}</Styled.MyRoleDetails>
          <Styled.YearHeading>Year</Styled.YearHeading>
          <Styled.YearDetails>{year}</Styled.YearDetails>
        </Styled.DescriptionList>
        <Content dangerouslySetInnerHTML={{ __html: html }} />
      </article>
      <Flex
        alignItems="center"
        direction="column"
        gap="xl"
        css={{
          marginTop: '$4xl',
          '@lg': {
            marginTop: '$5xl',
          },
        }}
      >
        <Text
          align="center"
          size="lg"
          weight="bold"
        >
          Thanks for watching!
          <br />
          🙏
        </Text>
        <Button
          accent="cyan"
          to={WorkPagePath}
          asGatsbyLink
        >
          See more work
        </Button>
      </Flex>
    </ContentLayout>
  )
}

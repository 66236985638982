import { graphql } from 'gatsby'
import * as React from 'react'
import { Layout } from '~/common/Layout/Layout'
import { SEO } from '~/common/Seo/Seo'
import { Work } from '~/features/WorkTemplate/Work/Work'
import { WorkData } from '~/types/work'

const WorkTemplate = (workData: WorkData) => (
  <Layout>
    <Work workData={workData} />
  </Layout>
)

export default WorkTemplate

export const Head = ({ data: { markdownRemark: post } }: WorkData) => (
  <SEO
    title={post.frontmatter.title}
    description={post.frontmatter.description || post.excerpt}
  />
)

export const pageQuery = graphql`
  query WorkBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        responsibilities
        role
        year
      }
    }
  }
`

import { GatsbyImage } from 'gatsby-plugin-image'
import { styled } from '~/ui/theme'

export const Header = styled('header', {
  marginBottom: '$3xl',
})

export const Image = styled(GatsbyImage, {
  mx: '-$base',
  overflow: 'hidden',
  radius: '$sm',

  '@lg': {
    mx: '-$3xl !important',
  },
})

export const DescriptionList = styled('dl', {
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gridGap: '$xs',
  margin: '$lg 0 $4xl',

  '@sm': {
    gridTemplateColumns: '2fr 1fr',
    gridTemplateAreas: `'a b' 'c d'`,
  },
})

const DescriptionTerm = styled('dt', {
  fontSize: '$md',
  lineHeight: '$md',
  fontWeight: 'bold',
})

const DescriptionDetails = styled('dd', {
  marginLeft: 0,
  fontSize: '$base',
  lineHeight: '$base',
})

export const MyRoleHeading = styled(DescriptionTerm, {
  '@sm': {
    gridArea: 'a',
  },
})

export const MyRoleDetails = styled(DescriptionDetails, {
  '@sm': {
    gridArea: 'c',
  },
})

export const YearHeading = styled(DescriptionTerm, {
  '@sm': {
    gridArea: 'b',
  },
})

export const YearDetails = styled(DescriptionDetails, {
  '@sm': {
    gridArea: 'd',
  },
})
